const $ = require('jquery')

$(function () {
    $("div#toggle").on("click", function () {
        $("nav.side").toggleClass("active");
    });
});

$(function () {
    $(".mapOpener").on('click', function () {
        $(".listContainer").toggleClass("open");
    })
});

function setHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  
  setHeight();
  
  window.addEventListener('resize', setHeight);