navigator.geolocation.getCurrentPosition(position => {
  const latitudeNode = document.querySelector('.currentPosition input[type=hidden][name=latitude]')
  if (!!latitudeNode) {
    latitudeNode.value = position.coords.latitude
  }
  const longitudeNode = document.querySelector('.currentPosition input[type=hidden][name=longitude]')
  if (!!longitudeNode) {
    longitudeNode.value = position.coords.longitude
  }
}, error => {
  const fieldSet = document.querySelector('fieldset#around-search')
  if (!!fieldSet) {
    fieldSet.setAttribute('disabled', 'disabled')
  }
});
