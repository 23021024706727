import { createApp, onMounted } from 'vue'
import DetailGoogleMap from '../components/top/DetailGoogleMap.vue'

const app = createApp({
  components: {
    DetailGoogleMap
  },
  data() {
    return {
      test: true
    }
  },
})
app.mount('#ishikawa-detail')
