function resetForm() {
  if (window.confirm('検索条件をクリアします')) {
    document.querySelectorAll('input:checked').forEach(element => {
      element.checked = false
    })
    document.querySelectorAll('input[type=hidden]').forEach(element => {
      element.value = ''
    })
    document.querySelector('#choice').textContent = '選択してください'
  }
}
window.resetForm = resetForm;
