<template>
  <div class="pointsMap">
    <iframe
      frameborder="0"
      style="border: 0"
      referrerpolicy="no-referrer-when-downgrade"
      v-bind:src="gmapUrl"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
export default {
  props: {
    lat: Number,
    lng: Number,
  },
  computed: {
    gmapUrl() {
      const url = "https://www.google.com/maps/embed/v1/place?";

      const params = {
        key: process.env.GOOGLE_MAP_API_KEY,
        center: `${this.lat},${this.lng}`,
        q: `${this.lat},${this.lng}`,
        zoom: 17,
      };

      return url + new URLSearchParams(params).toString();
    },
  },
};
</script>
