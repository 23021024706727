const $ = require('jquery')

$(function () {
  $('#sort').on('click', function () {
    // プルダウン開閉 
    $('#sortList').slideToggle('fast');
  });

  $('input[type=radio]').on('click', function () {
    // 選択時のclassを解除 
    $('li').removeClass('selected');
    // 選択時のclassを設定
    $('input[type=radio]:checked').parent().addClass('selected');
    // 擬似選択肢にアイコンと文字列を設定
    var label = $('label[for=' + $('input[type=radio]:checked').attr('id') + ']').html();
    $('#sort').html(label);

    // 選択後閉じる
    $('#sortList').slideUp('fast');
  });
});
