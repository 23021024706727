const $ = require('jquery')

$(function () {
  const selectChoice = (function () {
    // 選択時のclassを解除 
    $('li').removeClass('selected');
    // 選択時のclassを設定
    $('input[type=radio]:checked').parent().addClass('selected');
    // 擬似選択肢にアイコンと文字列を設定
    var label = $('label[for=' + $('input[type=radio]:checked').attr('id') + ']').html();
    $('#choice').html(label);
  })

  $('#choice').on('click', function () {
    // プルダウン開閉 
    $('#choiceList').slideToggle('fast');
  });

  $('input[type=radio]').on('click', function () {
    selectChoice()

    // 選択後閉じる
    $('#choiceList').slideUp('fast');
  });

  $(window).on('load', selectChoice)
});

// indexのセレクトボックス色
function changeColor(node) {
  if (node.value == 0) {
    node.style.color = '';
  } else {
    node.style.color = '#333';
  }
}
window.changeColor = changeColor;
