function submitConfirm(_token) {
  let valid = true
  document.querySelectorAll('input[required]:invalid').forEach(_node => {
    valid = false
  })
  if (!valid) {
    window.alert('必須項目が入力されていません')
    return
  }
  if (window.confirm('送信します。よろしいですか？')) {
    document.querySelector('#formSubmit').submit();
  }
}
window.submitConfirm = submitConfirm;
