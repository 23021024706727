<template>
  <div class="mapContainer">
    <div class="resultGoogleMap" ref="gmap"></div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  data() {
    return {
      currentMarker: null
    }
  },
  props: {
    lat: Number,
    lng: Number,
    zoom: Number,
  },
  created() {
    const loader = new Loader({
      apiKey: process.env.GOOGLE_MAP_API_KEY,
      version: "weekly",
    });

    loader.load().then((google) => {
      const center = { lat: parseFloat(this.lat), lng: parseFloat(this.lng) };
      const map = new google.maps.Map(this.$refs.gmap, {
        center,
        zoom: this.zoom,
        scaleControl: true,
      });

      // 現在地
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("latitude") && searchParams.has("longitude")) {
        new google.maps.Marker({
          position: {
            lat: parseFloat(searchParams.get("latitude")),
            lng: parseFloat(searchParams.get("longitude")),
          },
          icon: {
            fillColor: "#FF0000",
            fillOpacity: 0.8,
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 6,
            strokeColor: "#FF0000",
            strokeWeight: 1.0,
          },
          map,
          title: "現在地",
        });
      }

      const facilities = document.querySelectorAll(".bf-facilities");
      for (const key in facilities) {
        const facility = facilities[key];
        const dataset = facility.dataset;
        if (!dataset?.bfLatitude || !dataset?.bfLongitude) continue;

        const content = `<p class="mapWindow-name"><strong>${dataset.bfName}</strong></p>` +
          `<a class="mapWindow-detailLink" href=/detail/${dataset.bfId}>詳細情報ページ</a>`
        const infoWindow = new google.maps.InfoWindow({
          content
        })

        const marker = new google.maps.Marker({
          position: {
            lat: parseFloat(dataset.bfLatitude),
            lng: parseFloat(dataset.bfLongitude),
          },
          map,
          title: dataset.bfName,
        });
        marker.addListener('click', () => {
          this.currentMarker?.close()
          infoWindow.open({
            anchor: marker,
            map,
            shouldFocus: true
          })
          this.currentMarker = infoWindow
        })
      }
    });
  },
};
</script>
