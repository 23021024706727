import 'jquery/dist/jquery.js'

import './top/detail.js'
import './top/map.js'
import './top/aroundLocation.js'

import './top/select.js'
import './top/sort.js'
import './top/toggle.js'
import './top/submitConfirm.js'
import './top/searchForm.js'

// 共通
require('izimodal/css/iziModal.min.css')

const $ = require('jquery')
require('izimodal/js/iziModal')

const modalElement = document.querySelector('#modal')
if (modalElement) {
  $('#modal').iziModal({
    zindex: 9999,
    padding: 5
  })
  $('#modal').iziModal('open')
}
